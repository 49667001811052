import React from "react";
import { useTranslation, Trans } from "react-i18next";
import "./Crisishulp.css";
import individualCounseling from "./img/individual-counseling.png";
import crisisCounseling from "./img/crisis-counseling.png";

export function Crisishulp() {
  const { t, i18n } = useTranslation("crisishulp");

  return (
    <div className="crisishulp flex">
      <div className="crisishulp-sidebar"></div>
      <div className="right-side">
        <h1 className="title title-underlined">{t("title")}</h1>
        <p className="p1">
          <Trans i18nKey={"p1"} t={t}>
            <a
              href={
                i18n.language === "nl"
                  ? "https://www.bloomup.org/voor-mezelf"
                  : i18n.language === "en"
                  ? "https://www.bloomup.org/en/for-myself"
                  : "https://www.bloomup.org/fr/pour-moi"
              }
            >
              BloomUp platform
            </a>
          </Trans>
        </p>
        <p className="p2">{t("p2")}</p>
        <div className="counseling-list-wrapper">
          <div className="icon-and-text">
            <img className="icon" src={individualCounseling} />
            <h2 className="text">{t("individualCounseling.title")}</h2>
          </div>
          <div>
            <div>{t("how.text1")}</div>
            <div className="mt-4">
              <b>1. {t("how.item1")}</b>
            </div>
            <div>{t("how.text2")}</div>
            <div className="mt-4">
              <b>2. {t("how.item2")}</b>
            </div>
            <div>{t("how.text3")}</div>
            <div className="mt-4">
              <b>3. {t("how.item3")}</b>
            </div>
            <div>{t("how.text4")}</div>
            <div className="mt-4">
              <b>4. {t("how.item4")}</b>
            </div>
            <div>{t("how.end1")}</div>
            <div className="mb-4 mt-4">
              <a
                className="button yellow w-fit"
                href={"https://app.bloomup.org/matching/start"}
              >
                {t("how.end2")}
              </a>
            </div>
          </div>
        </div>
        <div className="counseling-list-wrapper mt-4">
          <div className="icon-and-text">
            <img className="icon" src={crisisCounseling} />
            <h2 className="text">{t("crisisCounseling.title")}</h2>
          </div>
          <div>
            <div className="mb-4">{t("specificCounseling.send")}</div>
            <ul>
              <li>{t("crisisCounseling.generalPractitioner")}</li>
              <li>{t("crisisCounseling.emergencyRoom")}</li>
              <li>{t("crisisCounseling.teleOnthaal")}</li>
              <li>{t("crisisCounseling.suicideHotline")}</li>
              <li>
                <Trans i18nKey="specificCounseling.depression" t={t}>
                  <a href="https://depressiehulp.be" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.fear" t={t}>
                  <a href="https://angsthulp.be" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.eatingDisorders" t={t}>
                  <a href="https://anbn.be" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.drugHotline" t={t}>
                  <a
                    href={
                      i18n.language === "nl"
                        ? "https://druglijn.be"
                        : i18n.language === "en"
                        ? "https://www.druglijn.be/english/"
                        : "https://druglijn.be"
                    }
                  />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.drugHelp" t={t}>
                  <a href="https://vind-een-psycholoog.be" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.alcoholism" t={t}>
                  <a
                    href={
                      i18n.language === "nl"
                        ? "https://www.zelfhulp.be/zoek-een-zelfhulpgroep/alcoholisme"
                        : i18n.language === "en"
                        ? "https://www.zelfhulp.be/zoek-een-zelfhulpgroep/alcoholisme"
                        : "https://www.alcooliquesanonymes.be/"
                    }
                  />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.buddywerking" t={t}>
                  <a href="https://buddywerking.be" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.psychosenet" t={t}>
                  <a href="https://psychosenet.be/espreekuur" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.sexualAssault" t={t}>
                  <a
                    href={
                      i18n.language === "nl"
                        ? "https://zsg.belgium.be/nl"
                        : i18n.language === "en"
                        ? "https://sac.belgium.be/en"
                        : "https://cpvs.belgium.be/fr"
                    }
                  />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.violenceAndAbuse" t={t}>
                  <a
                    href={
                      i18n.language === "nl"
                        ? "https://1712.be"
                        : i18n.language === "en"
                        ? "https://www.1712.be/en"
                        : "https://1712.be"
                    }
                  />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.sexualOrientation" t={t}>
                  <a href="https://lumi.be" />
                </Trans>
              </li>
              <li>
                <Trans i18nKey="specificCounseling.transgenderInfo" t={t}>
                  <a href="https://transgenderinfo.be" />
                </Trans>
              </li>
            </ul>
          </div>
        </div>
        <p>{t("p3")}</p>
      </div>
    </div>
  );
}
